import React, { useEffect, useRef } from "react";
import "./Participants.css";
import { connect, useSelector } from "react-redux";
import { Participant } from "./Participant/Participant.component";
import Requestscomponent from "../Requests/Requestscomponent";
import "../Requests/Request.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye
} from "@fortawesome/free-solid-svg-icons";

const Participants = (props) => {
  const videoRef = useRef(null);
  const showRequests = props?.showRequests;
  let participantKey = Object.keys(props.participants);
  const userAuth = useSelector(state => state.currentUser);
  let typeUser = null;
  let request = null;
  if (userAuth !== null && userAuth !== undefined) {
    const key = Object.keys(userAuth)[0];
    const nestedObject = userAuth[key];
    typeUser = nestedObject.typeUser;
    request = nestedObject.request;
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = props.stream;
      videoRef.current.muted = true;
    }
  }, [props.currentUser, props.stream]);

  const currentUser = props.currentUser
    ? Object.values(props.currentUser)[0]
    : null;

  let gridCol =
    participantKey.length === 1 ? 1 : participantKey.length <= 4 ? 2 : 4;
  const gridColSize = participantKey.length <= 4 ? 1 : 2;
  let gridRowSize =
    participantKey.length <= 4
      ? participantKey.length
      : Math.ceil(participantKey.length / 2);

  const screenPresenter = participantKey.find((element) => {
    const currentParticipant = props.participants[element];
    return currentParticipant.screen;
  });

  if (screenPresenter) {
    gridCol = 1;
    gridRowSize = 2;
  }
  let memebrs = [];
  const participants = participantKey.map((element, index) => {
    let currentParticipant = props.participants[element];
    currentParticipant = { ...currentParticipant, ref: element };
    const isCurrentUser = currentParticipant.currentUser;
    if (isCurrentUser) {
      return null;
    }
    const pc = currentParticipant.peerConnection;
    const remoteStream = new MediaStream();
    let curentIndex = index;
    if (pc) {
      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
        const videElement = document.getElementById(
          `participantVideo${curentIndex}`
        );
        if (videElement) videElement.srcObject = remoteStream;
      };
    }
    
    if (currentParticipant.request == "processing" || currentParticipant.request == "completed") {
      memebrs = [...memebrs, currentParticipant];
    }
    let showParticipant = (currentParticipant.request !== "completed" && currentParticipant.typeUser !== "admin") == true ? false : true;
    return (
      <Participant
        key={curentIndex}
        currentParticipant={currentParticipant}
        curentIndex={curentIndex}
        showParticipant={showParticipant}
        hideVideo={screenPresenter && screenPresenter !== element}
        showAvatar={
          !currentParticipant.video &&
          !currentParticipant.screen &&
          currentParticipant.name
        }
      />
    );
  });

  return (
    <div
      style={{
        "--grid-size": gridCol,
        "--grid-col-size": gridColSize,
        "--grid-row-size": gridRowSize,
      }}
      className={`participants`}
    >
      {participants}
      {
        (request !== null && (request === "completed" || typeUser === "admin")) && (
          <Participant
            currentParticipant={currentUser}
            curentIndex={participantKey.length}
            hideVideo={screenPresenter && !currentUser.screen}
            videoRef={videoRef}
            showParticipant={true}
            showAvatar={currentUser && !currentUser.video && !currentUser.screen}
            currentUser={true}
          />
        )
      }


      {
        (typeUser !== null && typeUser == "admin" && showRequests == true) && (
          <Requestscomponent memebrs={memebrs} />
        )
      }
      <div className="number-participants">
        <FontAwesomeIcon
          icon={faEye}
          title="Views"
        />{participants?.length}
      </div>
    </div>
  );
};




const mapStateToProps = (state) => {
  return {
    participants: state.participants,
    currentUser: state.currentUser,
    stream: state.mainStream,
  };
};

export default connect(mapStateToProps)(Participants);
