import firebase from "firebase";
import Login from "../components/Auth/Login";


var firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY, // Add API Key
  databaseURL: process.env.REACT_APP_DATABASEURL // Add databaseURL
};





// export const userName = prompt("What's your name?");

// if (userName === null || userName === undefined || userName === '') {
//   window.location.reload();
// }


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase;
export const firebaseRef = firebase.database().ref();
var firepadRef = firebaseRef;
const urlparams = new URLSearchParams(window.location.search);
//const roomId = urlparams.get("id");
export const login = urlparams.get("login");
export const passWord = urlparams.get("passWord");
//default Room :
firepadRef = firepadRef.child("-Nb6t12799rtL5GN1Nhl");
export default firepadRef;
