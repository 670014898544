import React, { useState } from 'react';
import './auth.css';
import App from '../../App';
import { useNavigate } from 'react-router-dom';
function YourName() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };

    const handleSave = (e) => {
        if (userName === '') {
            setErrors("من فضلك ادخل اسمك");
        } else {
            navigate('/', { state: { userName: userName } });
        }
    };

    return (
        <div className="login-container" style={{ direction: 'rtl' }}>
            <div className="login-box">
                <form>
                    {
                        errors !== '' && (
                            <p className='errors'>{errors}</p>
                        )
                    }
                    <div className="form-group">
                        <label style={{ float: '' }} >أدخل اسم المستخدم الخاص بك</label>
                        <input
                            type="text"
                            required
                            placeholder="أدخل اسم المستخدم الخاص بك"
                            value={userName}
                            onChange={handleUserNameChange}
                        />
                    </div>
                    <button type="button" onClick={handleSave}>
                        حفظ
                    </button>
                </form>
            </div>
        </div>
    );
}

export default YourName;
