import React, { useState } from 'react';
import './auth.css'; // Import your CSS file for styling
import App from '../../App';
import { useNavigate } from 'react-router-dom';
function Login() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleLogin = (e) => {
    if (login === '' || password === '' || userName === '') {
      setErrors("Ensure all fields are filled out !");
      return;
    }
    if (process.env.REACT_APP_LOGIN === login && process.env.REACT_APP_PASSWORD === password) {
      navigate('/', { state: { login: login, passWord: password, userName: userName } });
    } else {
      setErrors("The login or password you entered is incorrect !");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Admin</h1>

        <form>
          {
            errors !== '' && (
              <p className='errors'>{errors}</p>
            )
          }
          <div className="form-group">
            <label>Login</label>
            <input
              type="text"
              required
              placeholder="Enter your email"
              value={login}
              onChange={handleLoginChange}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              required
              type="password"
              autoComplete="new-password"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group">
            <label>Username</label>
            <input
              required
              type="text"
              placeholder="Enter your username"
              value={userName}
              onChange={handleUserNameChange}
            />
          </div>
          <button type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
