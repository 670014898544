import React, { useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import YourName from './components/Auth/YourName';
import App from './App';

export default function MiddleWare() {
    const { state } = useLocation();
    const userName = state?.userName;
    const passWord = state?.passWord;
    const login = state?.login;

    return <>
        {
            (userName === null || userName === undefined || userName === '') == true ? <YourName /> :
                <App userName={userName} passWord={passWord} login={login} />
        }
    </>

}
