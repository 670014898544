
import React from 'react'
import "../Request.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import firepadRef from '../../../server/firebase'
import { useSelector } from 'react-redux'

export default function RequestComponent({ member }) {

    const onAccept = (ref) => {
        const participantRef = firepadRef.child("participants");
        const currentParticipantRef = participantRef
            .child(ref)
            .child("preferences");
        setTimeout(() => {
            currentParticipantRef.update({
                request: "completed"
            });
        });
    }
    const onClose = (ref) => {
        const participantRef = firepadRef.child("participants");
        const currentParticipantRef = participantRef
            .child(ref)
            .child("preferences");
        setTimeout(() => {
            currentParticipantRef.update({
                request: "pending"
            });
        });
    }

    return (
        <div className='member'>
            <div>
                <span
                    style={{ background: member?.avatarColor }}
                    className="avatar-member"
                >
                    {member?.name[0]}
                </span>
                <span className='request-name'>
                    {member?.name}
                </span>
            </div>

            <div className='actions'  >
                {
                    (member?.request === "processing") && (
                        <span className='action' onClick={() => onAccept(member?.ref)}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                title="Accept"
                            />
                        </span>
                    )
                }

                {
                    (member?.request === "completed") && (
                        <span className='action' onClick={() => onClose(member?.ref)}>
                            <FontAwesomeIcon
                                icon={faWindowClose}
                                title="Close"
                            />
                        </span>
                    )
                }

            </div>

        </div>
    )
}