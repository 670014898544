
import React from 'react'
import "./Request.css";
import RequestComponent from './Request/RequestComponent';

export default function Requestscomponent({ memebrs }) {
    return (
        <>
            <div className='requests-container'>
                <h4 className='title'>Requests</h4>
                {
                    memebrs.map((item, index) => {
                        return (
                            <RequestComponent member={item} />
                        )
                    })
                }
            </div>
        </>
    )
}