import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux';
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash, faHandPeace, faList

} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "./MeetingFooter.css";
import firepadRef from "../../server/firebase";



const MeetingFooter = (props) => {
  const { setShowRequests, showRequests } = props;
  const [streamState, setStreamState] = useState({
    mic: false,
    video: false,
    screen: false,
    hasPermission: false,
    request: "pending"
  });


  const currentUser = useSelector(state => state.currentUser);
  const participants = useSelector(state => state.participants);
  let typeUser = null;
  let currentParticipant = null;
  if (currentUser !== null && currentUser !== undefined) {
    const key = Object.keys(currentUser)[0];
    const nestedObject = currentUser[key];
    typeUser = nestedObject.typeUser;
    currentParticipant = participants[key];
  };


  if (currentParticipant?.request !== null && currentParticipant?.request === "pending" && streamState.mic === true && typeUser !== "admin") {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: false,
        request: "pending"
      };
    });
  }






  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });

  };

  const onAskRequest = () => {
    const currentUserRef = Object.keys(currentUser)[0];
    const participantRef = firepadRef.child("participants");
    const currentParticipantRef = participantRef
      .child(currentUserRef)
      .child("preferences");
    setTimeout(() => {
      currentParticipantRef.update({
        request: "processing"
      });
    });

    // // console.log(currentUser);

    // firepadRef.child('requests').push({
    //   currentUserRef,
    //   status: "pending"
    // });

    // // setStreamState((currentState) => {
    // //   return {
    // //     ...currentState,
    // //     request: "processing",
    // //   };
    // // });
  }

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };


  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };

  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);
  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);


  
  return (


    
    <div className="meeting-footer">




      {
        ((typeUser !== null && typeUser == "admin") || (currentParticipant?.request !== null && currentParticipant?.request == "completed")) && (
          <div
            className={"meeting-icons " + (!streamState.mic ? "active" : "")}
            data-tip={streamState.mic ? "Mute Audio" : "Unmute Audio"}
            onClick={micClick}
          >
            <FontAwesomeIcon
              icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
              title="Mute"
            />
          </div>
        )
      }


      {

        
        (typeUser !== null && typeUser == "user" && currentParticipant?.request !== "completed" ) && (<div
          className={`meeting-icons1 get-permission ${(currentParticipant?.request !== null && currentParticipant?.request == "processing") ? "active1" : ""}`}
          data-tip={"Get permission"}
          onClick={onAskRequest}
        >
            
            <span className="text">اريد التحدث</span> 
                
           <FontAwesomeIcon icon={faHandPeace} />
           
        </div>)

        
      }

<div className="meeting-footer111">
<lable3 style={{ }} >(GMT+1)بودكاست كل يوم على الساعة 23:30</lable3>
</div>



      {
        (typeUser !== null && typeUser == "admin") && (
          <div
            className={"meeting-icons"}
            data-tip={"Requests"}
            onClick={() => setShowRequests(!showRequests)}
          >
            <FontAwesomeIcon icon={faList} />
          </div>
        )
      }




      {/* <div
        className={"meeting-icons " + (!streamState.video ? "active" : "")}
        data-tip={streamState.video ? "Hide Video" : "Show Video"}
        onClick={onVideoClick}
      >
        <FontAwesomeIcon icon={!streamState.video ? faVideoSlash : faVideo} />
      </div> */}
      {/* <div
        className="meeting-icons"
        data-tip="Share Screen"
        onClick={onScreenClick}
        disabled={streamState.screen}
      >
        <FontAwesomeIcon icon={faDesktop} />
      </div> */}
      <ReactTooltip />
    </div>

  );

  
};

export default MeetingFooter;
