import MainScreen from "./components/MainScreen/MainScreen.component";
import firepadRef, { db, login } from "./server/firebase";
import "./App.css";
import { useEffect } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
} from "./store/actioncreator";
import { connect } from "react-redux";

function App(props) {
  const userName = props?.userName;
  const passWord = props?.passWord;
  const login = props?.login;

  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices?.getUserMedia({
      audio: true,
      video: false,
    });
    return localStream;
  };


  useEffect(async () => {
    const stream = await getUserStream();
    stream.getAudioTracks()[0].enabled = false;
    //stream.getVideoTracks()[0].enabled = false;
    props.setMainStream(stream);
    connectedRef.on("value", (snap) => {
      if (snap.val()) {
        const defaultPreference = {
          audio: false,
          video: false,
          screen: false,
          request: 'pending'
        };

        let userStatusRef = null;
        if (userName !== null && userName !== '' && userName !== undefined) {
          userStatusRef = participantRef.push({
            userName,
            typeUser: (login != undefined && login != null && login != '' && passWord != undefined && passWord != null && passWord != '') ? "admin" : "user",
            preferences: defaultPreference,
          })
        } 
        props.setUser({
          [userStatusRef.key]: { name: userName, typeUser: (login != undefined && login != null && login != '' && passWord != undefined && passWord != null && passWord != '') ? "admin" : "user", ...defaultPreference },
        });

        userStatusRef.onDisconnect().remove();
      }
    });
  }, []);

  const connectedRef = db.database().ref(".info/connected");
  const participantRef = firepadRef.child("participants");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, preferences = {}, typeUser: typeUser } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            ...preferences,
            typeUser
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
  }, [isStreamSet, isUserSet]);

  return (
    <div className="App">
      <MainScreen />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    user: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
